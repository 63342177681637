:where(.css-dev-only-do-not-override-98ntnt).ant-modal .ant-modal-footer {

  display: none !important;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 32px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 30px;
  //padding-bottom: 44px;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-modal .ant-modal-close {
  position: absolute;
  top: 18px;
  right: 30px;
  color: black;
  &:hover {
    background: transparent !important;
  }
}
.modal-content {
  .pass-modal-header {
    border-bottom: 1px solid gainsboro;
    padding-bottom: 15px;
   // padding: 10px 35px 15px 35px;
  }
  .pass-modal-content {
    padding: 37px 34px 0px 34px;
    .confirm-pass-container{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
      .c-pass-div {
        width: 45%;
        .curnt-pass {
          width: 100%;
          height: 46px;
          border-radius: 7px;
          border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
          background: var(--White, #f9fafc);
        }
       
      }
  
    }
   
  }
}
.passContent{
  max-width: 1000px;
  width: 100%;
}
.textAreaa{
  padding: 10px;
  padding-left: 20px;
  border-radius: 8px;
  border: 1px solid gainsboro;
  &:focus-within{
    outline: none;
  }
}
@media screen and (max-width: 800px) {
  .modal-content {
    .pass-modal-content {
      flex-wrap: wrap;
      .confirm-pass-container{
        flex-direction: column;

        .c-pass-div {
          width: 100%;
          .curnt-pass {
            width: 100% !important;
            flex-direction: column;
          }
        }
      }
    
      .confirm-pass-container{
        flex-direction: column;
        gap: 0px;
      }
    }
  }

}

@media screen and (max-width:1700px) {
  :where(.css-dev-only-do-not-override-98ntnt).ant-modal .ant-modal-content {
 
    // width: 350px;
    margin: 0 auto !important;
  
}
}

@media screen and (max-width: 600px){
  .passContent{
    width: 100%;
  }

}