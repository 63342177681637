.userCardContainer {
  //width: 835.673px;
  //height: 335px;
  //border: 2px solid purple;
  border-radius: 14.618px;
  background: #fff;
  box-shadow: 0px 20px 37.2px 10px rgba(0, 133, 255, 0.28);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 596.196px;
  margin-top: 80px;
  .leftPart {
    //border: 1px solid green;
    width: 30%;
    //display: flex;
    //justify-content: flex-start;
    // align-items: flex-start;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .rightPart {
    width: 70%;
    padding: 47px 64px 80px 51px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    position: relative;
    .quote {
      position: absolute;
      right: 20px;
      top: 50px;
      width: 80px;
      height: 80px;
    }
  }
}
@media screen and (max-width:1600px) {
  .userCardContainer{
  
    .rightPart{
      padding:30px 14px 31px 19px !important;
      .quote{
        top: 20px;
        width: 60px;
        height: 60px;
      }
    
    }
  }
  
}


@media screen and (max-width:1440px) {
  .userCardContainer{
    .rightPart{
      padding:30px 14px 21px 19px !important;
      p{
        font-size: 13px;
      }
    }
  }
  
}


@media screen and (max-width:600px) {
  .userCardContainer{
   // border: 1px solid gainsboro;
    flex-direction: column;
    padding-top: 0px;
    box-shadow: 0px 5px 6px 0px rgba(0, 133, 255, 0.28);
   
    .rightPart{
  width: 100%;
  align-items: center;
  justify-content: center;
  .quote{
    top: -60px;
    right: 10px;
    }
      p{
        font-size: 13px;
        text-align: center;
      }
    }
    .leftPart{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        border-radius: 50%;
        width: 100px;
        height: 100px;
        
      }
    }
  }
  
}