.accContainer {
  padding: 32px 95px 31px 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
 // border: 1px solid purple;
  .manageDiv {
    border-bottom: 1px solid gainsboro;
  }
  .profileData{

  }
  .emailBox{
    margin-top: 79px;
    margin-bottom: 57px;
  }
  .passwordBox {

    div {
      .input-div {
        width: 250px;
        height: 27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 220px;
          height: auto;
          border-radius: 7px;
          border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
          background: var(--White, #f9fafc);
          padding: 7px 13px 7px 13px;
          font-size: 15px;
          &::placeholder{
            font-size: 15px;
          }
        }
        img{
            width: 16px;
            height: 16px;
        }
      }
    }
  }
}
@media screen and (max-width:1440px) {
  .accContainer{
    padding: 20px;
  }
  
}

@media screen and (max-width:800px){
.accContainer{
  .manageDiv{
    width: 100%;
  }
  .emailBox{
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .profileData{
    flex-direction: column;
    margin-bottom: 30px;
    gap: 5px;
    font-size: 15px;

  }
  .passwordBox{
    .input-wrapper{
      
      min-width: 100% !important;

    }
    div{
      .input-div{
        width: 100%;

        // input{
        //   width: 90%;
        // }
      }
    }
  }
}

}