.promptDiv {
  // background-color: #F9FAFC;
  border-radius: 10px;
  border: 1px solid gainsboro;
  max-width: 97%;
  position: absolute !important;
  width: 100%;
  bottom: 20px;
  left: 0;

  .promptArea1 {
    width: 5%;
    border-right: 1px solid gainsboro;
    padding: 12px 18px;
  }
  .promptArea2 {
    width: 90%;
    padding: 12px 18px;
    color: gray;
    input {
      background-color: #f9fafc;
    }
  }
  .promptArea3 {
    width: 5%;
    border-left: 1px solid gainsboro;
    padding: 12px 18px;
  }
}
:where(.css-dev-only-do-not-override-98ntnt).ant-modal-root .ant-modal-wrap {
  position: fixed;
  inset: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  backdrop-filter: blur(10px);
  -webkit-overflow-scrolling: touch;
}
.chatbot-wrap {
  position: fixed;
  bottom: 95px;
  right: 60px;
  .chatbot-meassge-box {
    min-height: auto;
    position: relative;
    .chatDiv {
      position: absolute;
      width: 316px;
      height: 460px;
      border-radius: 10px;
      background: #f9fafc;
      box-shadow: 0px 4px 15.7px 0px rgba(0, 0, 0, 0.18);
      bottom: 80px;
      right: 22px;
      .header {
        height: 70px;
        border-radius: 10px 10px 0px 0px;
        background: var(
          --Gradiant,
          linear-gradient(92deg, #009eff 0.8%, #9360ff 98.13%)
        );
        padding: 10px 30px;
      }
      .innerContentWrapper {
        padding: 41px 13px 11px 17px;
        height: 100%;
        max-height: 390px;
        .parentChatBox {
          display: flex;
          flex-direction: column;
          gap: 23px;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          .senderMsg {
            background: white;
            border: 1px solid gainsboro;
            padding: 5px;
            max-width: 90%;
            margin-left: auto;
            border-radius: 4px;
            p {
              font-size: 12px;
            }
          }
          .receiverMsg {
            max-width: 90%;
            border-radius: 4px;
            background: var(--primary);
            color: white;
            padding: 11px 5px 8px 5px;
            p {
              font-size: 13px;
              line-height: 17px;
            }
          }
        }

        .msgTextField {
          border-radius: 30px;
          background: #fff;
          box-shadow: 0px 1px 5.5px 0px rgba(0, 0, 0, 0.12);
          height: 40px;
          position: relative;
          .msgSendIcon {
            position: absolute;
            top: 3px;
            right: 4px;
          }
          input {
            padding: 11px 5px 10px 23px;
            border-radius: 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .promptDiv {
    bottom: 20px;
  }

  .chatbot-wrap {
    bottom: 85px;
    right: 35px;
  }
}

@media screen and (max-width: 800px) {
  .promptDiv {
    bottom: -300px;
    .promptArea1 {
      padding: 15px 22px;
    }
    .promptArea3 {
      padding: 15px 22px;
    }
  }
  .chatbot-wrap {
    bottom: 83px;
    right: 15px;
    .chatbot-meassge-box {
      img {
        width: 40px;
      }
      .chatDiv {
        bottom: 55px;
        right: 7px;
      }
    }
  }
  // .chatbot-wrap  {

  //   bottom: 85px;
  //   right: 35px;
  // }
}
