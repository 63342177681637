.nav-wrapper {
  margin-top: 19px;
 // border: 1px solid black;
  .container {
    .innerNav {
      padding: 0px 20px;
      .navLogo {
      }
      .navContent {
        ul {
          gap: 51px;
        }
      }
      .navBtns {
        display: flex;
        gap: 41px;
      }
    }
  }
}
@media screen and (max-width: 3000px) {
  .mobile-navbar {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .nav-wrapper {
    .container {
      .innerNav {
        padding: 0px;
        gap: 38px;
        .navContent{
          ul{
            gap: 40px;
          }
        }
        .navBtns{
          gap: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .nav-wrapper {
    display: none;
  }

  .mobile-navbar {
    width: 100%;
    height: 68px;
    //background: #6d43ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .mobileLogo {
      width: 100px;
      height: 25px;
    }

    .menu-btn {
      background: transparent;
      border: none;
      width: 56px;
      height: 39px;
      // margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .offcanvas {
    width: 100% !important;
  }
  .offcanvas-header {
    background: white;
    justify-content: space-between;
    align-items: center;
  }
  .offcanvas-body {
    // background: #3F60DD;
    color: var(--white);
    background: white;
    width: 100% !important;
  }
  .Offcanvas.Title {
    img {
      width: 30px;
      height: 30px;
    }
  }
  .blue-button {
    border: 2.3px solid #fff !important;

    &:hover {
      background-image: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
      border: none !important;
    }
  }
  .logostyle {
    width: 100px;
    height: 25px;
  }
  li {
    margin-bottom: 20px;
    font-size: 18px;
    color: black;
    list-style: none;
    a {
      color: black;
      text-decoration: none;
    }
  }

  // .signupBtn {
  //   width: auto;
  //   //margin-left: 30px;
  //   padding: 10px;
  //   text-align: center;
  //   font-size: 20px;
  //   line-height: normal;
  //   border: 1px solid black;
  //   color: black;
  //   border-radius: 10px;
  //   background-color: white;
  // }
}
.offcanvas-header .btn-close {
  display: none;
}

