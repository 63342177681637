.featureSection {
  margin-top: 800px;
  margin-bottom: 240px;
  //border: 1px solid red;
  .container {
    .innerArea {
        display: flex;
        justify-content: space-between;
        margin-top: 128px;
      .leftArea {
      // border: 1px solid blue;
        width: 38%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       

        display: flex;
        li {
          display: flex;
          gap: 23px;
          margin-bottom: 57px;
          .featureIcons{

          }
        }
      }
      .rightArea {
        //border: 1px solid rgb(49, 49, 49);
        display: flex;
        width: 55%;
        height: auto;
        align-items: center;
        justify-content: center;
        img{
            width: 100%;
            object-fit: cover;
          
        }
      }
    }
  }
}

@media screen and (max-width:1440px) {

  .featureSection{
    
    margin-top: 350px;
    margin-bottom: 100px;
    .container{
      .innerArea{
        margin-top: 74px;
       
        .leftArea{
          width: 50%;
          h4{
            font-size: 23px;
          }
          p{
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media screen and (max-width:1040px){
  .featureSection{
    margin-top: 250px;
  
  }

}
@media screen and (max-width:800px){
  .featureSection{
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 5px;
    .container{
      .innerArea{
        flex-direction: column;
        gap: 10px;
        margin-top: 40px;
       
        .leftArea{
          width: 100%;
          li{
            margin-bottom: 30px;
            flex-direction: column;
            .featureIcons{
              max-width: auto;
              margin: auto;
            }
            h4{
              font-size: 20px;
              line-height: normal;
              text-align: center;
              margin-bottom: 10x;
            }
            p{
              font-size: 14px;
              text-align: center;
              margin-top: 10px;
            }
          }
       
        }
        .rightArea{
          width: 100%;
        }
      }
    }
  }


}