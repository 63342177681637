.headingContainer{
   max-width: 882px;
    margin: 0 auto;
    h1{
        line-height: normal;
letter-spacing: -0.48px;
margin-bottom: 19px;
    }
    p{
        line-height: 160%; /* 28.8px */
    }
}

@media screen and (max-width:1440px) {

    .headingContainer{
        
        h1{
            font-size: 38px;
        }
        p{
            font-size: 17px;
        }
    }
}

@media screen and (max-width:600px) {

    .headingContainer{
        h1{
            font-size: 25px;
        }
        p{
            font-size: 16px;
        }
    }
}