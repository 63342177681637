.cardsContainer {
  width: 400px;
  height: 269px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;

  &:hover{
    .gradientDiv{
        width: 70%;
        margin: 0 auto;
    }
    img{
        transform: translateY(-20px);
    }
}
  .gradientDiv{
    width: 26px;
height: 6px;
background: linear-gradient(92deg, #009EFF 0.8%, #9360FF 98.13%);
border-radius: 20px;
transition: 0.7s ease-in-out;


  }
  img{
transition: 0.7s ease-in-out;

  }
  h4 {
    letter-spacing: -0.24px;
    line-height: normal;
    // background: darkcyan;
  }
  p {
    letter-spacing: -0.24px;
    line-height: normal;
  }
}
@media screen and (max-width:1440px) {

  .cardsContainer{
    p{
      font-size: 17px;
    }
    h4{
      font-size: 23px;
      min-height: 30px;
      text-align: center;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width:1200px) {

  .cardsContainer{
    p{
      font-size: 16px;
    }
    h4{
      font-size: 21px;
    }
  }
}

@media screen and (max-width:600px) {

  .cardsContainer{
    height: auto;
    width: 100%;
    img{
    width: 60px;
    }
    p{
      font-size: 16px;
    }
    h4{
      font-size: 20px;
    }
  }
}