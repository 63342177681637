.billingContainer {
  min-height: 100vh;
  width: 100%;

  .leftArea {
    width: 40%;
    background-image: url("../../../../../assets/images/dashboard/billing-left-pic.png");
    background-repeat: no-repeat;
    // background-position: center;
    background-size: 100% 100%;

    height: 100%;
    //border: 1px solid blue;
    height: 100vh;
    position: relative;

    .arrowLeft {
      position: absolute;
      top: 40px;
      left: 70px;
    }
    .leftContent {
      padding: 128px 125px 20px 85px;
    }
  }
  .rightArea {
    width: 70%;
    // border: 1px solid red;
    .blackArrow {
    }
    .midBox {
      max-width: 412px;
      .emailBox {
        .emailInput {
          border-radius: 7px;
          border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
          background: var(--White, #f9fafc);
          height: 66px !important;
        }
      }
    }
  }
}
:where(.css-dev-only-do-not-override-98ntnt).ant-input-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  height: 46px;
}
@media screen and (max-width: 1440px) {
  .billingContainer {
    .leftArea {
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 25px;
      }
      .leftContent {
        padding: 128px 20px 20px 20px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .billingContainer {
    .leftArea {
      display: none;
    }
    .rightArea {
 position: relative;
      .blackArrow {
    position: absolute;
      top: -220px;
      left: 40px;
        
        display: block !important;
        .blackLeftArrow{
      
        }
      }
    }
  }
}
@media screen and (max-width: 600px){

.billingContainer{
  .rightArea{
    padding: 20px;
    .midBox{
      max-width: 100%;
      gap: 30px !important;
      .text-div{
        text-align: center;
        h3{
          font-size: 23px;
          line-height: normal;
        }
      }
    }
  }
}
}