.billContainer {
  padding: 32px 34px 43px 28px;
  .billStatment {

  }
  .currentDta{
     padding-bottom: 32px;
    border-bottom: 1px solid gainsboro;
    margin-top: 10px;
  }

  .tableSection {
    .tableWrapper {
      border-radius: 10px;
      box-shadow: 0px 4px 14.4px 0px rgba(0, 0, 0, 0.08);

      .ant-table-wrapper {
        border-radius: 10px !important;
        padding-bottom: 30px;

        box-shadow: 0px 4px 14.4px 0px rgba(0, 0, 0, 0.08);

        .ant-pagination {
          margin-top: 170px; /* Adjust as needed */
          display: flex;
          justify-content: center;
        }

        .ant-pagination-item-active {
          background-color: var(--primary)!important;
          border-color: var(--primary) !important;
        }
        
        .ant-pagination-item-active a {
          color: white !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .billContainer {
    padding: 10px;
    .tableSection {
        padding: 20px;
      .tableWrapper {
      }
    }
  }
  :where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
    .ant-table-container {
   
    overflow-x: scroll !important;
  }
}
