.ourUserSection {
  overflow: hidden;
  margin-bottom: 200px;

  .inner {
    margin-top: 114px;
  }

  .arrow-slider-btns {
    //margin-top: 140px !important;
    //  border: 1px solid green;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 55px !important;

    .testi-prev-btn {
      position: relative;
      width: 54px;
      height: 54px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary);
      padding: 18px;
      border-radius: 50px;
      outline: none;
    }
    .testi-next-btn {
      position: relative;
      width: 54px;
      height: 54px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary);
      padding: 18px;
      border-radius: 50px;
      transform: rotate(180deg);
      outline: none;
    }
  }

  // .slick-center {
  // opacity: 0.5;

  // }

  // .slick-active{
  //   transform: scale(1.3);
  //   transition: 0.7s ease-in-out;

  // }

  .slick-slide {
    transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
    opacity: 0.5;
  }

  .slick-center {
    transform: scale(1.1);
    opacity: 1 !important;
    z-index: 1;
    position: relative;
  }
  .slick-track {
    height: 400px;
  }
  .slick-list {
    height: 600px;
  }

  // .slick-slide {
  //     transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
  //     opacity: 0.5; /* Default opacity for all slides */
  //   }

  //   .slick-center {
  //     transform: scale(1.3);
  //     opacity: 1 !important; /* Ensure center slide has full opacity */
  //   }
}
@media screen and (max-width: 1600px) {
  .ourUserSection {
    margin-bottom: 150px;
    .slick-list{
      height: 500px;
    }
  }
}
@media screen and (max-width: 1200px){
  .ourUserSection{
    .slick-list{
      height: 600px;
    }
  }
}
@media screen and (max-width: 900px) {
  .ourUserSection {

    .slick-list{
    height: auto;
    }
    .arrow-slider-btns{
      .testi-prev-btn{
        width: 45px;
        height: 45px;
      }
      .testi-next-btn{
        width: 45px;
        height: 45px;
      }
    }
  }
}

@media screen and (max-width: 600px){
.ourUserSection{
  margin-bottom: 0px !important;
  margin-top: 50px;
  padding: 20px;
  .slick-list{
    height: 500px;
  }
  .inner{
    margin-top: 50px;
  }
}
}


@media screen and (max-width: 460px){
  .ourUserSection{
    margin-bottom: 50px !important;
    .slick-list{
      height: 590px;
    }
  }
  }