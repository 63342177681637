.profileContent {
  padding: 32px 95px 31px 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 39px;
  justify-content: left;
  .billingDiv {
  }
  form {
    width: 100%;
    .nameDiv {
      gap: 73px;
      .fnameContainer {
        width: 100%;

        .ftrEmail {
          max-width: 100%;
          width: 100%;
          height: 46px;
        }
      }
    }
    .saveProfileBtn {
      height: 93px;
    }
    .error-message {
      color: red;
      font-size: 13px;
      
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 1440px) {
  .profileContent {
    padding: 0px;
    padding-left: 20px;
    .billingDiv{
      p{
        font-size: 14px;
      }
    }
    form{
      .nameDiv{
        .fnameContainer{
          label{
            font-size: 14px;
          }
        }
      }
    }
   
  }
}
@media screen and (max-width: 850px){
    .profileContent {
     
        form {
          .nameDiv {
            gap: 20px;
            flex-direction: column;
          }
        }
      }
    
}