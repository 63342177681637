
  .pricingCardsContainer {
    display: flex;
    padding-bottom: 63px;
    margin-top: 32px;

 
  
    .pricingContainer {
      width: 334px;
      //min-height: 700px;
      flex-shrink: 0;
      border-radius: 13px;
      background-color: #fff !important;
      box-shadow: 0px 11px 32.6px -4px rgba(0, 0, 0, 0.2);
     // border: 3px solid transparent;
      position: relative;
   
      &:after {
        content: " ";
        position: absolute;
        top: -5px;
        bottom: -5px;
        left: -5px;
        right: -5px;
        background: linear-gradient(180deg, #0a41d6, #c67dfa);
        z-index: -1;
        border-radius: 13px;
        background-color: white;
      }
  
      &:hover {
        
        .subscribeBtn {
          background-color: #009eff !important;
          color: white;
          transition: background-color 0.5s linear, color 0.5s linear;
        }
  
        .badge {
          background: linear-gradient(90deg, #009eff 0%, #9360ff 100%);
          transition: background 0.5s linear;
        }
      }
  
      .header {
        display: flex;
        justify-content: space-around;
        padding: 53px 10px 43px 20px;
        align-items: center;
  
        .cardHeading {
          display: flex;
          align-items: flex-end;
          h1{
            font-size: 52px;
            font-weight: 700;
          }
          p{
            font-size: 14px;
          }
     
        }
  
        .badge {
          background-color: #0085ff;
          color: white;
          padding: 8px 18px;
          border-radius: 19px;
          justify-content: center;
          align-items: center;
          //height: 30px;
          font-size: 14px;
          font-weight: 400;
        }
      }
  
      .cardBody {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 80px;
   
  
        ul {
          padding: 31px 53.5px;
          min-height: 400px;
        }
  
        .chexkImage {
          width: 16px;
          height: 16px;
        }
  
        .listItems {
          margin-bottom: 24px;
          display: flex;
          justify-content: left;
          font-size: 16px;
          align-items: baseline;
        }
  
        .subscribeBtn {
          width: 90%;
          position: absolute;
          bottom: 5%;
          right: 5%;
          left: 5%;
          margin: auto;
          padding: 16px 140px;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          background: var(--White, #f9fafc);
          box-shadow: 0px 2px 8.2px 0px rgba(0, 0, 0, 0.08);
          border-radius: 5px;
          border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
        }
      }
    }
  }

  @media screen and (max-width:700px){
    
    
  }

