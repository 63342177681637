.moneySection {
  width: 100%;
  height: 85vh;
  background-image: url("../../../assets/images/home/moneySecBack.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  .container {
    .inner {
      max-width: 1200px;

      padding-top: 200px !important;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //border: 1px solid red;
      gap: 34px;
      h1 {
        font-size: 64px;
        line-height: normal;
        letter-spacing: -0.64px;
      }
      p {
        margin-bottom: 12px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .moneySection {
    .container {
      .inner {
        h1 {
          font-size: 50px;
        }
        p{
          font-size: 18px;
        }
      }
    }
  }
}


@media screen and (max-width: 1440px) {
  .moneySection {
    height: auto;
    margin-bottom: 90px;
    .container {
      .inner {
        padding-top: 50px !important;
        h1 {
          font-size: 38px;
        }
        p{
          font-size: 17px;
        }
      }
    }
  }
}


@media screen and (max-width: 1200px) {
  .moneySection {

    .container {
      .inner {
        padding-top: 20px !important;
        h1 {
          font-size: 25px;
        }
        p{
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .moneySection{
    margin-bottom: 110px;
  }
}