.userFeedbackContainer {
  padding: 32px 34px 30px 56px;
  .feedbackText {
    padding-bottom: 34px;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 32px;
  }
  .textArea {
    width: 100%;
    margin-left: 15px;
    height: 143px;
    border-radius: 7px;
border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
background: var(--White, #F9FAFC);
padding: 20px;
  }
}
