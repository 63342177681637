.Dashboard-main-container {
    color: #000000;
    min-height: 100vh;
    height: 100%;
    background-color:#F9FAFC;
    position: relative;
    .left-content {
      padding: 15px 0px !important;
    }
    .right_content {
      padding: 15px 0px 0px 0px;
      width: calc(100vw - 242px);
      margin-left: 310px !important;
      background-color:#F9FAFC;
      //background: rgb(73, 56, 56);
    
    }
    .dashboardMenu{
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 9999;
    }
  }
  @media only screen and (max-width: 1700px) {
    .Dashboard-main-container {
      .right_content {
        width: calc(100vw - 250px) !important;
        margin-left: 300px !important;
      }
    }
  }
  @media only screen and (max-width: 1025px) {
    .Dashboard-main-container {
      margin-bottom: 20px !important;
      .right_content {
        width: 100% !important;
        margin-left: 0px !important;
        padding: 20px;
       
      }
      .dashboardMenu{
        display: block !important;
      }
    }
  }
  
  @media only screen and (max-width: 696px) {
    .Dashboard-main-container {

      .right_content {
        width: 100% !important;
        margin-left: 10px !important;
        padding: 0px !important;
        min-height: 100vh;
      }
    }
  }