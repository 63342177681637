.userManagementWrapper{
    padding: 32px 41px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .user-header{
        padding-bottom: 16px;
        border-bottom: 1px solid gainsboro;
    }
    .userContent{
        padding: 20px 40px;
        flex: 1;
        .userSearchField{
            width: 70%;
            height: 46px;
            margin-bottom: 42px !important;
            margin: 0 auto;
            border-radius: 7px;
            border: 1px solid gainsboro;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            padding: 13px 15px;
            &::placeholder{
                color: var(--field-text);
            }
        }
        .tableWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            font-weight: 500;
            .ant-pagination {
                margin-top: 170px; /* Adjust as needed */
                display: flex;
                justify-content: center;
              }

              .ant-pagination-item-active {
                background-color: var(--primary)!important;
                border-color: var(--primary) !important;
              }
              
              .ant-pagination-item-active a {
                color: white !important;
              }
          }
    }
}

@media screen and (max-width:1000px) {

    .userManagementWrapper{
        padding: 10px;
        .userContent{
            padding: 20px;
            .tableWrapper{
               
            }
        }
    }

    :where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper .ant-table-container {
        position: relative;
        overflow-x: scroll;
    }
}