@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

h1 {
  font-size: 48px;
  line-height: 40px;
}

h2 {
  font-size: 36px;
  line-height: 40px;
}

h3 {
  font-size: 30px;
  line-height: 40px;
}
h4 {
  font-size: 24px;
  line-height: 40px;
}

h5 {
  font-size: 22px;
  line-height: 40px;
}
h6 {
  font-size: 20px;
  line-height: 40px;
}
.text-large {
  font-size: 18px;
  line-height: 24px;
}

p,
label,
.text-med {
  font-size: 16px;
}

.text-small {
  font-size: 14px;
}
.cursor-pointer {
  cursor: pointer;
}
.gray-btn{
  border: 1px solid gray !important;
}
// @media screen and (max-width:1440px) {
//   h1 {
//   font-size: 38px;
//   line-height: 40px;
// }

// h2 {
//   font-size: 32px;
//   line-height: 40px;
// }

// h3 {
//   font-size: 28px;
//   line-height: 40px;
// }
// h4 {
//   font-size: 23px;
//   line-height: 40px;
// }

// h5 {
//   font-size: 21px;
//   line-height: 40px;
// }
// h6 {
//   font-size: 19px;
//   line-height: 40px;
// }
// .text-large {
//   font-size: 17px;
//   line-height: 24px;
// }

// p, label, .text-med {
//   font-size: 15px;

// }

// .text-small {
//   font-size: 13px;

// }

// }

// @media screen and (max-width:1200px) {
//   h1 {
//   font-size: 30px;
//   line-height: 40px;
// }

// h2 {
//   font-size: 30px;
//   line-height: 40px;
// }

// h3 {
//   font-size: 26px;
//   line-height: 40px;
// }
// h4 {
//   font-size: 21px;
//   line-height: 40px;
// }

// h5 {
//   font-size: 20px;
//   line-height: 40px;
// }
// h6 {
//   font-size: 18px;
//   line-height: 40px;
// }
// .text-large {
//   font-size: 16px;
//   line-height: 24px;
// }

// p, label, .text-med {
//   font-size: 14px;

// }

// .text-small {
//   font-size: 13px;

// }

// }

// @media screen and (max-width:600px) {
//   h1 {
//   font-size: 25px;
//   line-height: 40px;
// }

// h2 {
//   font-size: 24px;
//   line-height: 40px;
// }

// h3 {
//   font-size: 23px;
//   line-height: 40px;
// }
// h4 {
//   font-size: 20px;
//   line-height: 40px;
// }

// h5 {
//   font-size: 19px;
//   line-height: 40px;
// }
// h6 {
//   font-size: 18px;
//   line-height: 40px;
// }
// .text-large {
//   font-size: 16px;
//   line-height: 24px;
// }

// p,
// label,
// .text-med {
//   font-size: 14px;
// }

// .text-small {
//   font-size: 13px;

// }

// }
