// Input style here

.input-wrapper {
  border-radius: 7px;
  height: 65px;
  display: flex;
  align-items: center;
  //padding: 0 22px;
  position: relative;
  background-color:#F9FAFC !important;
border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
  // background: var(--secondary);
  text-transform: lowercase;

  .imgAbsolute{
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 2;
  }


  input {
    color: var(--gray-text);
    border: 0;
    background-color: #F9FAFC!important;
    width: 100%;
    height: 100%;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 2;
    padding: 0 22px;
    border-radius: 7px;
 
  
    &::placeholder {
      font-size: 14px;
 
    }
  }
  &.error {
    border: 2px solid var(--danger);
  }

  label {
    font-family: Lato;
    font-size: 14px;
    text-transform: lowercase !important;
    font-weight: 400;
    color: var(--text-color);
    position: absolute;
    white-space: nowrap;
    padding: 16px 10px 16px 11px;
    line-height: 100%;
    color: #aaa;
    // transition: all 0.3s linear;
    // top: 50%;
    // transform: translateY(-50%);
    z-index: 1;
  }

  &:focus-within {
    border: 1.5px solid gainsboro;
  }
}

.input-wrapper:focus-within label,
.input-wrapper input:not([value=""]) + label,
.input-wrapper input[type="date"] + label {
  // top: 0px;
  // left: 15px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.075px;
  color: #333843;
  background: var(--white);
  padding: 0 10px;
  text-transform: lowercase;
}

.input-wrapper:focus-within .hide-on-focus,
.input-wrapper input[type="search"]:not([value=""]) + label {
  display: none;
}

// ----------------------- input type two

.input-wrapper-two {
  border-radius: 7px;
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0 22px;
  position: relative;
  background: transparent;
  
  border: 1.5px solid var(--secondary);
  input {
    color: var(--primary);
    border-radius: 7px;
    background-color: #F9FAFC;
    width: 100%;
    height: 100%;
  
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 2;
    position: relative;

   
  }
  &.error {
    border: 2px solid var(--danger);
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 6px;
  //   background-color: var(--accent);
  // }
  label {

    font-size: 20px;
    font-weight: 400;
    color: var(--primary);
    position: absolute;
    white-space: nowrap;
    line-height: 100%;
    color: #aaa;
    transition: all 0.3s linear;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    text-transform: lowercase;
  }

  &:focus-within {
    border: 1.5px solid #3385ff;
  }
}

textarea.help {
  border-radius: 10.863px;
  border: 1.358px solid var(--faded-text, #9e9e9e);
  width: 675.124px;
  height: 338px;
  padding: 12px;

  &:focus-visible {
    border: none;
    outline: 1.358px solid var(--accent);
  }
}
input::-webkit-autofill {
  background-color: transparent !important;
  background: transparent !important;
}
