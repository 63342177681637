.heroSection {
  background-image: url("../../../assets/images/home/heroBack.webp");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 78vh;
  position: relative;

  .container {
    .inner {
      max-width: 969px;
      margin: 0 auto;
      margin-top: 111px !important;
      gap: 23px;
      h1 {
        line-height: 117.7%;
        text-transform: capitalize;
        z-index: 5;
      }
      h6 {
        line-height: 158.7%; /* 31.74px */
        letter-spacing: -0.6px;
      }
      .heroBtn {
        margin-top: 33px;
      }
      .macbook {
        margin-top: 140px;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .heroSection {
     height: 100vh;
    margin-top: 30px !important;
    
    .container {
      .inner {
        margin-top: 30px !important;
        padding-top: 80px;
        h1 {
          font-size: 38px;
        }
        h6 {
          font-size: 19px;
        }
        .macbook {
          width: 100%;
          height: 100%;
          margin-top: 50px;
         
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .heroSection {
    .container {
      .inner {
        h1 {
          font-size: 30px;
        }
        h6 {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .heroSection {
    height: auto;
    .container {
      .inner {
        margin-top: 50px !important;
        padding-top: 16px;
        h1 {
          font-size: 25px;
        }
        h6 {
          font-size: 18px;
        }
        .macbook{
          margin-top: 40px;
        }
      }
    }
  }
}
