:root {
  --dark-black: #090B1E;
  --white: #ffffff;
  --primary: #0085ff;
  --secondary: #f9fafc;
  --dim-white: #E3E3E3;
  --field-text: #646571;
  --gray-text: #090B1E;
  --dark-grayy:#626370;
  --cheryred:#FF3F3F;
  --dark-bluish:#363C4F;
  --parrot-green:#0AB139;


}
.dark-black{
    color: var(--dark-black);
}
.white{
    color: var(--white);
}
.primary{
    color: var(--primary);
}
.secondary{
    color: var(--secondary);
}
.dim-white{
    color: var(--dim-white);
}
.field-text{
    color: var(--field-text);
}
.gray-text{
    color: var(--gray-text);
}
.dark-gray{
    color: var(--dark-grayy);
}
.text-red{
    color: var(--cheryred);
}
.dark-bluish{
    color: var(--dark-bluish);
}
.parrot-green{
    color: var(--parrot-green);
}