.benfitSection{
position: relative;
margin-bottom: 300px;

.glow{
    position: absolute;
    top: -500px;
}
.container{
    .innerArea{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 120px;
        margin-top: 130px;
    }
}
   
}

@media screen and (max-width:1440px) {
    .benfitSection{
        margin-bottom: 100px;
        .glow{
display: none;
        }
        .container{
            .innerArea{
                gap: 50px;
            }
        }
    }
    
}

@media screen and (max-width:600px) {
    .benfitSection{
        margin-bottom: 50px;
        .container{
            .innerArea{
                flex-direction: column;
                margin-top: 50px;
             
            }
        }
    }
    
}