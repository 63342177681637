.pricing {
  background-image: url(../../../assets/images/pricingPage/pricingBackground.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  .cardHeading h1 {
    font-weight: 700;
  }

  // .buttonWrap {
  //   display: flex;
  //   justify-content: center;
  //   gap: 20px;
  //   margin-top: 24px;
  //   margin-bottom: 32px;
  // }
  .buttonWrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    width: 241px;
    padding: 3px;
    // border: 2px solid black;
    border-radius: 5px;
    box-shadow: 0px 4px 10.7px 0px rgba(0, 0, 0, 0.12);
    margin: auto;
    margin-top: 24px;
    margin-bottom: 32px;

    .btn {
      width: 100%;
    }
  }
  .pricingCardsContainer {
    display: flex;
    padding-bottom: 63px;

    .pricingContainer {
      min-height: 700px;
      flex-shrink: 0;
      border-radius: 13px;
      background: #fff;
      box-shadow: 0px 11px 32.6px -4px rgba(0, 0, 0, 0.2);
     // border: 3px solid transparent;
      position: relative;
    
      


      &:after {
        content: "dfdff";
        position: absolute;
        top:-5px;
        bottom: -5px;
        left: -5px;
        right: -5px;
         background-image: linear-gradient(180deg, #0a41d6, #c67dfa) !important;
        z-index: -1;
        border-radius: 13px;
       background-color: white;
      }

      
      &:hover {
        // border-radius: 13px;
        // border: 3px solid;
        // border-image: linear-gradient(90deg, #009eff 0%, #9360ff 100%);
        .subscribeBtn {
          background-color: #009eff !important;
          color: white;
          transition: background-color 0.5s linear, color 0.5s linear;
        }
        .badge {
          background: linear-gradient(90deg, #009eff 0%, #9360ff 100%);
          transition: background 0.5s linear;
        }
      }
     
     
     
      .header {
        display: flex;
        justify-content: space-around;
        padding: 53px 10px 43px 20px;
        align-items: center;
        position: relative;
        z-index: 1;
        .cardHeading {
          display: flex;
          align-items: center;
          gap: 5px;

          p{
            margin-top: 20px;
           
          }
        }
        .badge {
          background-color: #0085ff;
          color: white;
          padding: 8px 18px;
          border-radius: 19px;
          justify-content: center;
          align-items: center;
          //height: 30px;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .cardBody {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ul {
          padding: 31px 53.5px;
          min-height: 400px;
          position: relative;
          z-index: 1;
        }
        .chexkImage {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        .listItems {
          margin-bottom: 24px;
          display: flex;
          justify-content: left;
          font-size: 16px;
          gap: 10px;
        }
        .subscribeBtn {
          width: 90%;
          margin: auto;
          padding: 16px 140px;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          background: var(--White, #f9fafc);
          box-shadow: 0px 2px 8.2px 0px rgba(0, 0, 0, 0.08);
          border-radius: 5px;
          border: 1px solid var(--Stroke, rgba(187, 187, 187, 0.38));
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .pricing .pricingCardsContainer .pricingContainer .header .badge {
    font-size: 18px;
  }
  .pricing{
    .pricingCardsContainer{
      .pricingContainer{
        .header{
          .cardHeading{
            h1{
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
  .pricing .cardHeading h1 {
    font-size: 31px;
  }
}
@media screen and (max-width: 768px) {
  .pricingCardsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1440px) {
  .pricing .pricingCardsContainer .pricingContainer .header {
    justify-content: space-between;
    padding: 43px 10px 33px 10px;
  }
  .pricing .pricingCardsContainer .pricingContainer .header {
    padding: 31px 20px;
  }
  .pricing .pricingCardsContainer .pricingContainer .cardBody ul {
    padding: 31px 20px;
  }
  .pricing .pricingCardsContainer .pricingContainer .header .badge {
    font-size: 14px;
  }
}
